import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const app = initializeApp({
  apiKey: 'AIzaSyAy-BQZxNeQaxUYo82ce2CzVmNTn88Smpk',
  authDomain: 'spant-7002c.firebaseapp.com',
  projectId: 'spant-7002c',
  storageBucket: 'spant-7002c.appspot.com',
  messagingSenderId: '185707716287',
  appId: '1:185707716287:web:d8596e3c7bead184bb700a',
  measurementId: 'G-E25WBTH7TV'
});

getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
