import logo from './../assets/logo-yellow-blue.svg';

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="footer">
          <div className="vcard">
            <div className="logo">
              <img
                src={logo}
                alt="logo"
                title="Bureau Spant - Projectbegeleiding & BIM engineering"
              />
            </div>
            <div className="contact">
              <address>
                Kleinmansbeek 2 <br></br>
                3772 TW Barneveld <br></br>
                <span>M</span> <a href="tel:+31620735504">+31 (0)6 2073 5504</a>
                <br></br>
                <span>E</span>{' '}
                <a href="mailto:info@bureauspant.nl">info@bureauspant.nl</a>
              </address>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
