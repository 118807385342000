import './App.scss';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import Usp from './components/Usp';

function App() {
  return (
    <div className="wrapper">
      <Header></Header>
      <Hero></Hero>
      <Usp></Usp>
      <Footer></Footer>    
    </div>
  );
}

export default App;
