import DefinitiefOntwerp from './../assets/usp/definitief-ontwerp.svg';
import TechnischOntwerp from './../assets/usp/technisch-ontwerp.svg';
import Omgevingsvergunningen from './../assets/usp/omgevingsvergunningen.svg';
import UitvoeringsOntwerp from './../assets/usp/uitvoerings-ontwerp.svg';

function Usp() {
  return (
    <section className="usp">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>
              Wij zijn inzetbaar in verschillende fasen van het ontwerp- en
              bouwvoorbereidingsproces
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="usp-item">
              <div className="usp-header">
                <div className="usp-image">
                  <img
                    src={DefinitiefOntwerp}
                    alt="Definitief ontwerp"
                    title="Definitief ontwerp"
                  />
                </div>
                <h3>
                  Definitief<br></br> ontwerp
                </h3>
              </div>
              <p>
                Met kennis van techniek en regelgeving werken wij een voorlopig
                (schets)ontwerp van de architect uit tot een definitief ontwerp.
                Hiermee kan het ontwerp worden vastgesteld.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="usp-item">
              <div className="usp-header">
                <div className="usp-image">
                  <img
                    src={TechnischOntwerp}
                    alt="Technisch ontwerp"
                    title="Technisch ontwerp"
                  />
                </div>
                <h3>
                  Technisch<br></br> ontwerp
                </h3>
              </div>
              <p>
                Op basis van een definitief ontwerp wordt een project technisch
                uitgewerkt. Knooppunten worden gedetailleerd, materialisering
                verwerkt en het constructie ontwerp in beeld gebracht.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="usp-item">
              <div className="usp-header">
                <div className="usp-image">
                  <img
                    src={Omgevingsvergunningen}
                    alt="Omgevingsvergunningen"
                    title="Omgevingsvergunningen"
                  />
                </div>
                <h3>
                  Omgevings-<br></br>vergunningen
                </h3>
              </div>
              <p>
                Het project wordt aangevuld met bouwbesluitinformatie en er
                worden stukken van diverse adviseurs verzameld en verwerkt in
                het tekenwerk. Vervolgens verzorgen wij de complete aanvraag
                omgevingsvergunning.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="usp-item">
              <div className="usp-header">
                <div className="usp-image">
                  <img
                    src={UitvoeringsOntwerp}
                    alt="Uitvoerings ontwerp"
                    title="Uitvoerings ontwerp"
                  />
                </div>
                <h3>
                  Uitvoerings<br></br> ontwerp
                </h3>
              </div>
              <p>
                De BIM-modellen en tekeningen worden verder uitgewerkt zodat het
                project klaar is voor uitvoering en aansturing van
                onder-aannemers en leveranciers. Onze BIM-modellen zijn geheel
                conform de ILS Ontwerp & Engineering.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Usp;
