import { ReactComponent as Arrow } from '../assets/arrow.svg';
import { getAnalytics, logEvent } from "firebase/analytics";
import React from 'react';

class Hero extends React.Component {
  handleClick = () => {
    const analytics = getAnalytics();
    logEvent(analytics, 'get_in_touch');
  }

  render() {
    return (
    <section>
      <div className="container">
        <div className="hero">
          <h1>
            Realisatie zonder verrassingen door optimale voorbereiding met
            digitaal bouwen
          </h1>
          <p className="lead">
            Bureau Spant richt zich op bouwkundig BIM-engineering en
            projectbegeleiding van woning- en utiliteitsbouwprojecten. Wij doen
            dit in opdracht van architectenbureaus, bouwbedrijven en
            projectontwikkelaars.
          </p>
          <a href="mailto:info@bureauspant.nl" className="btn btn-primary" onClick={this.handleClick}>
            Neem contact op
            <Arrow></Arrow>
          </a>
        </div>
      </div>
    </section>
  )};
}

export default Hero;
