import logo from './../assets/logo-yellow.svg';

function Header() {
  return (
    <header>
      <div className="container">
        <div className="logo">
          <img
            src={logo}
            alt="logo"
            title="Bureau Spant - Projectbegeleiding & BIM engineering"
          />
        </div>
      </div>
    </header>
  );
}

export default Header;
